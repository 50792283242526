import React, { useState, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StyledAccountManager from './index.style';
import SearchHeader from './searchHeader';
import DataList from './dataList';
import useFetchData from './useFetchData';
import { useDebounce } from '../../../utils/hooks';
import NoPermission from '../../../components/NoPermission';

const AdminAccounts = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAdmin } = useSelector((state) => state.auth.user);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [userId, setUserId] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const [loading, data] = useFetchData({
    search: debouncedSearch,
    page,
    limit,
    userId,
  });

  const [totalCount, dataList] = useMemo(() => {
    if (!data) return [-1, []];
    return [data.count, data.results];
  }, [data]);

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAddAccountSuccess = (accountId) => {
    setUserId(accountId);
  };

  const handleDeleteAccountSuccess = (position, accountId) => {
    const startPosition = page * limit + 1;
    if (page !== 0 && position === startPosition && position === totalCount) {
      setPage(page - 1);
    } else {
      setUserId(userId === accountId ? 0 : accountId);
    }
  };

  if (!isAdmin)
    return (
      <NoPermission
        backText={t('backToDashboard')}
        handleBack={() => history.push('/dashboard')}
      />
    );

  return (
    <StyledAccountManager>
      <Typography variant="h5" className="title">
        {t('accountManage')}
      </Typography>
      <SearchHeader
        search={search}
        setSearch={setSearch}
        handleAddAccountSuccess={handleAddAccountSuccess}
      />
      <DataList
        dataList={dataList}
        totalCount={totalCount}
        page={page}
        limit={limit}
        loading={loading}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        handleDeleteAccountSuccess={handleDeleteAccountSuccess}
      />
    </StyledAccountManager>
  );
};

export default AdminAccounts;
