import React from 'react';
import { Card as CardWrapper } from '@material-ui/core';
import useStyles from './index.style';

const Card = ({ children, ...props }) => {
  const classes = useStyles(props);

  return <CardWrapper className={classes.root}>{children}</CardWrapper>;
};

export default Card;
