import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  name: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: '10px',
      fontWeight: 'bold',
    },
  },
  noData: {
    fontStyle: 'italic',
  },
  yellow: {
    backgroundColor: theme.palette.card.primary,
  },
  pink: {
    backgroundColor: theme.palette.card.secondary,
  },
  blue: {
    backgroundColor: theme.palette.card.tertiary,
  },
}));
