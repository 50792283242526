import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Avatar,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import TablePagination from '../../../components/TablePagination';
import DeleteAccountDialog from './Dialog/deleteAccountDialog';
import { randomAvatarColor } from '../../../configs/styleConstant';

function DataList({
  loading,
  dataList,
  totalCount,
  page,
  limit,
  handleChangePage,
  handleChangeRowsPerPage,
  handleDeleteAccountSuccess,
}) {
  const { t } = useTranslation();
  const [accountSelected, setAccountSelected] = useState(null);

  const onAccountSelected = (account) => {
    setAccountSelected(account);
  };

  const handleCloseDeleteDialog = () => {
    setAccountSelected(null);
  };

  const renderAvatar = (avatar, name) => {
    if (avatar) return <Avatar src={avatar} />;

    const words = name.split(' ');
    return (
      <Avatar
        className="avatar"
        style={{ backgroundColor: randomAvatarColor() }}
      >
        {words[words.length - 1].slice(0, 1).toUpperCase()}
      </Avatar>
    );
  };

  const renderTableBody = () => {
    if (dataList.length === 0 && !loading) {
      return (
        <TableRow>
          <TableCell>{t('noData')}</TableCell>
        </TableRow>
      );
    }

    return dataList.map(({ id, name, email, avatar }, index) => {
      const position = page * limit + index + 1;
      return (
        <TableRow hover key={id}>
          <TableCell>{position}</TableCell>
          <TableCell className="avatarName">
            {renderAvatar(avatar, name || '')} <span>{name}</span>
          </TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={() => onAccountSelected({ position, id, email })}
            >
              {t('delete')}
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className="wrapTable">
      {loading && (
        <div className="loadingTable">
          <CircularProgress />
        </div>
      )}
      <TableContainer>
        <Table
          className={dataList.length > 0 ? '' : 'noData'}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t('no')}</TableCell>
              <TableCell>{t('fullName')}</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>{t('action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mainTable">{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        showInputPage
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t('dataPerPage')}
      />
      {accountSelected && (
        <DeleteAccountDialog
          open={accountSelected !== null}
          handleClose={handleCloseDeleteDialog}
          account={accountSelected}
          handleDeleteAccountSuccess={handleDeleteAccountSuccess}
        />
      )}
    </div>
  );
}

export default DataList;
