import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const StyledAccountManager = styled(Paper)`
  padding: 10px 15px 0px 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  .title {
    padding-left: 8px;
    min-height: 56px;
    display: flex;
    align-items: center;
  }

  .searchHeader {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .MuiInputBase-formControl {
      width: 300px;
    }
  }

  .wrapTable {
    margin-top: 20px;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    min-height: 400px;

    .avatarName {
      display: flex;
      align-items: center;

      .avatar {
        font-size: 14px;
      }

      span {
        margin-left: 8px;
      }
    }
  }

  .MuiTableContainer-root {
    flex-grow: 1;
  }

  .MuiTablePagination-root {
    min-height: 52px;
  }

  .MuiLink-root {
    &:hover,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }

  .noData {
    .MuiTableBody-root .MuiTableCell-root {
      border-bottom: 0;
    }
  }
`;

export default StyledAccountManager;
