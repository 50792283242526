import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  FormControl,
  Input,
  InputAdornment,
  TablePagination,
  ButtonBase,
} from '@material-ui/core';
import {
  AddCircleOutlineOutlined,
  CompareArrows,
  Search,
} from '@material-ui/icons';
import useStyles from './index.style';
import Card from '../../../components/Card';
import NoData from '../../../components/NoData';
import CreateAppDialog from './CreateAppDialog';
import { changeAlias } from '../../../utils/string';
import api from '../../../apis';
import { useDebounce } from '../../../utils/hooks';
import { CALLBOT_CRM_URL } from '../../../configs';

const MAX_ITEM = 5;

const ListApp = () => {
  const [apps, setApps] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [visibleAppModel, setVisibleAppModel] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 600);
  const [page, setPage] = useState(0);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    const { data } = await api.livechat.getListApps({
      search: debouncedSearch,
      limit: MAX_ITEM,
      offset: page * MAX_ITEM,
    });
    if (data.status) {
      setApps(data.results.apps);
      setTotalCount(data.results.metadata.total);
    } else {
      enqueueSnackbar(t('cannotFetchApp'), { variant: 'error' });
    }
  };

  const handleViewApp = (id) => {
    window.location.assign(`${CALLBOT_CRM_URL}/apps/${id}/setting`);
  };

  const handleViewBot = (e, id) => {
    e.stopPropagation();
    window.location.assign(
      `${process.env.REACT_APP_SMARTDIALOG_DASHBOARD_DOMAIN}/api-client/agent/${id}/dashboard`,
    );
  };

  useEffect(() => {
    fetchData();
  }, [debouncedSearch, page]);

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const renderPagination = () => {
    if (totalCount > MAX_ITEM)
      return (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalCount}
          rowsPerPage={MAX_ITEM}
          page={page}
          onChangePage={handleChangePage}
        />
      );
    return null;
  };

  const handleRedirectToFbPage = (e, id) => {
    e.stopPropagation();
    window.open(`https://facebook.com/${id}`);
  };

  const getActiveFbNamePage = (facebook) => {
    const { id, pages } = facebook;
    const activePage = pages.find((fbPage) => fbPage.id === id);
    if (activePage) return activePage.name;
    return '';
  };

  return (
    <div>
      <Typography variant="h5" className={classes.appList}>
        {t('listApplication')}
      </Typography>
      <div className={classes.appHeader}>
        <FormControl className={classes.search}>
          <Input
            id="input-search-app"
            startAdornment={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            placeholder={t('search')}
            value={search}
            onChange={handleChangeSearch}
          />
        </FormControl>
        <Tooltip title={t('newApp')}>
          <IconButton color="primary" onClick={() => setVisibleAppModel(true)}>
            <AddCircleOutlineOutlined />
          </IconButton>
        </Tooltip>
        <CreateAppDialog
          visibleAppModel={visibleAppModel}
          setVisibleApp={(value) => setVisibleAppModel(value)}
        />
      </div>
      <div className={classes.listAppWrapper}>
        {!apps.length && <NoData text={t('noData')} />}
        {apps.map((app) => {
          const color = Math.floor(Math.random() * 3);
          return (
            <Card key={app.id} margin="0 0 16px 0">
              <ButtonBase
                className={classes.item}
                onClick={() => handleViewApp(app.id)}
              >
                <Avatar
                  variant="rounded"
                  src={app.avatar}
                  className={`${classes.logo} ${
                    color === 0 && classes.yellow
                  } ${color === 1 && classes.pink} ${
                    color === 2 && classes.blue
                  }`}
                >
                  {!app.avatar && changeAlias(app.name).slice(0, 1)}
                </Avatar>
                <div className={classes.appData}>
                  <div className={classes.appAndBot}>
                    <Typography className={classes.appName}>
                      {app.name}
                    </Typography>
                    {app.botName && (
                      <div className={classes.linkToBot}>
                        <CompareArrows />
                        <Typography
                          className={classes.botName}
                          onClick={(e) => handleViewBot(e, app.botId)}
                        >
                          {app.botName}
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div className={classes.messagingChannel}>
                    <Typography>{t('messagingChannel')}: </Typography>
                    {app.facebook && app.facebook.id && (
                      <Tooltip title={getActiveFbNamePage(app.facebook)}>
                        <Avatar
                          onClick={(e) =>
                            handleRedirectToFbPage(e, app.facebook.id)
                          }
                          className={classes.channelLogo}
                          variant="rounded"
                          src="/images/facebook-icon.svg"
                        />
                      </Tooltip>
                    )}
                    {app.zalo && (
                      <Avatar
                        className={classes.channelLogo}
                        variant="rounded"
                        src="/images/zalo-icon.svg"
                      />
                    )}
                    {app.viber && (
                      <Avatar
                        className={classes.channelLogo}
                        variant="rounded"
                        src="/images/viber-icon.svg"
                      />
                    )}
                    {app.telegram && (
                      <Avatar
                        className={classes.channelLogo}
                        variant="rounded"
                        src="/images/telegram-icon.svg"
                      />
                    )}
                    {app.aicc && (
                      <Avatar
                        className={classes.channelLogo}
                        variant="rounded"
                        src="/images/aicc-icon.svg"
                      />
                    )}
                    {!app.facebook &&
                      !app.zalo &&
                      !app.viber &&
                      !app.telegram &&
                      !app.aicc && (
                        <Typography className={classes.noData}>
                          {t('noData')}
                        </Typography>
                      )}
                  </div>
                </div>
              </ButtonBase>
            </Card>
          );
        })}
        {renderPagination()}
      </div>
    </div>
  );
};

export default ListApp;
