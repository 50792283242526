import React, { useState, useEffect } from 'react';

import {
  Typography,
  Button,
  DialogActions,
  Grid,
  Box,
  Avatar,
  TextField,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { StyledIntegrateBot, StyledPrebuiltBot } from './index.style';
import apis from '../../../apis';

const PrebuiltBot = ({ bot = {}, viewPrebuiltBot, usePrebuiltBot }) => {
  const [showAction, setShowAction] = useState(false);
  const { t } = useTranslation();
  return (
    <StyledPrebuiltBot
      onMouseEnter={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <Box
        className="bot-header clickable"
        onClick={() => viewPrebuiltBot(bot.id)}
      >
        <Typography className="name">{bot.name || ''}</Typography>
        <Avatar variant="rounded" src={bot.logo} />
      </Box>
      <Typography
        className="description clickable"
        onClick={() => viewPrebuiltBot(bot.id)}
      >{`${bot.description || ''}`}</Typography>
      {showAction && (
        <div className="action">
          <Button
            className="view-detail"
            onClick={() => viewPrebuiltBot(bot.id)}
          >
            {t('viewDetail')}
          </Button>
          <Button
            className="use-now"
            onClick={() =>
              usePrebuiltBot({
                id: bot.id,
                name: bot.name,
                logo: bot.logo,
              })
            }
          >
            {t('useNow')}
          </Button>
        </div>
      )}
    </StyledPrebuiltBot>
  );
};

const IntegrateBot = ({ toSecondStep, viewPrebuiltBot, usePrebuiltBot }) => {
  const [prebuiltBots, setPrebuiltBots] = useState([]);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const fetchPreBuiltBots = async () => {
    const response = await apis.smartdialog.getPreBuiltBots({
      search,
    });
    if (response?.data?.status)
      setPrebuiltBots(response?.data?.result.prebuiltBots);
  };

  const handleChangeSearch = (e) => setSearch(e.target.value);

  useEffect(() => {
    fetchPreBuiltBots();
  }, [search]);

  return (
    <StyledIntegrateBot>
      <div className="header">
        <div>
          <Typography className="title">{t('createAppFirstStep')}</Typography>
          <Typography className="subtitle">
            {t('subTitleFirstStepCreateApp')}
          </Typography>
        </div>
        <TextField
          fullWidth
          autoFocus
          variant="outlined"
          placeholder={t('enterBotNameToSearch')}
          value={search}
          onChange={handleChangeSearch}
          className="filter"
        />
      </div>
      <Grid container alignItems="stretch" spacing={3}>
        {prebuiltBots?.map((bot) => (
          <Grid item xs={4} key={bot.id}>
            <PrebuiltBot
              bot={bot}
              viewPrebuiltBot={viewPrebuiltBot}
              usePrebuiltBot={usePrebuiltBot}
            />
          </Grid>
        ))}
      </Grid>
      <DialogActions>
        <Button
          className="forawrd-button"
          variant="text"
          endIcon={<ArrowForward className="arrow-forward" />}
          onClick={toSecondStep}
          fontSize="small"
        >
          {t('skip')}
        </Button>
      </DialogActions>
    </StyledIntegrateBot>
  );
};

export default IntegrateBot;
