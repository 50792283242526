import * as auth from './auth';
import * as user from './user';
import * as livechat from './livechat';
import * as smartdialog from './smartdialog';
import * as account from './account';

export default {
  auth,
  user,
  livechat,
  smartdialog,
  account,
};
