import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Input, InputAdornment, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddAccountDialog from './Dialog/addAccountDialog';

function SearchHeader({ search, setSearch, handleAddAccountSuccess }) {
  const { t } = useTranslation();
  const [openAddAccount, setOpenAddAccount] = useState(false);

  const handleOpenAddAccount = () => {
    setOpenAddAccount(true);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div className="searchHeader">
      <FormControl>
        <Input
          id="input-search-account"
          startAdornment={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder={t('searchAccount')}
          value={search}
          onChange={handleChangeSearch}
        />
      </FormControl>

      <Button
        variant="contained"
        size="medium"
        color="primary"
        onClick={handleOpenAddAccount}
      >
        {t('addAccount')}
      </Button>
      <AddAccountDialog
        open={openAddAccount}
        setOpen={setOpenAddAccount}
        handleAddAccountSuccess={handleAddAccountSuccess}
      />
    </div>
  );
}

export default SearchHeader;
