import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { register } from '../../../../apis/auth';
import { validateEmail } from '../../../../utils/string';

function AddAccountDialog({ open, setOpen, handleAddAccountSuccess }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => {
    setName('');
    setEmail('');
    setOpen(false);
  };

  const onNameChanged = (event) => {
    setName(event.target.value);
    if (nameError) setNameError('');
  };

  const onEmailChanged = (event) => {
    setEmail(event.target.value);
    if (emailError) setEmailError('');
  };

  const onPasswordChanged = (event) => {
    setPassword(event.target.value);
  };

  const onClickShowPassword = () => setShowPassword(!showPassword);

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    if (name.length === 0) {
      setNameError(t('nameIsRequired'));
      return false;
    }
    if (email.length === 0) {
      setEmailError(t('emailIsRequired'));
      return false;
    }
    if (!validateEmail(email)) {
      setEmailError(t('emailInvalid'));
      return false;
    }

    return true;
  };
  const handleAddAccount = async () => {
    if (!validateForm()) return;
    const { data } = await register({ name, email, password });
    if (!data.status) {
      enqueueSnackbar(data.message, { variant: 'error' });
      return;
    }
    const { id: userId } = data.result.user;
    handleAddAccountSuccess(userId);
    setOpen(false);
    if (name) setName('');
    if (email) setEmail('');
    if (password) setPassword('');
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('addAccount')}</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          id="name"
          label={t('fullName')}
          type="text"
          fullWidth
          value={name}
          onChange={onNameChanged}
          error={nameError.length > 0}
          helperText={nameError}
        />
        <TextField
          variant="outlined"
          id="email"
          label="Email"
          type="email"
          fullWidth
          style={{ marginTop: 20 }}
          value={email}
          onChange={onEmailChanged}
          error={emailError.length > 0}
          helperText={emailError}
        />
        <TextField
          variant="outlined"
          id="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          style={{ marginTop: 20 }}
          value={password}
          onChange={onPasswordChanged}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={onClickShowPassword}
                  onMouseDown={onMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button color="primary" onClick={handleAddAccount}>
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAccountDialog;
