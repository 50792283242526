import styled from 'styled-components';

export const StyledUnauthorized = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .center {
    text-align: center;
  }

  .unauthorized-title {
    font-size: 24px;
    font-weight: bold;
  }

  .unauthorized-img {
    height: 50vh;
    margin-bottom: 48px;
  }
`;
