import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import api from '../../apis';
import axiosInstance from '../../apis/axiosInstance';
import actions from '../actions';
import { setCookie } from '../../utils/cookie';
import { responseCodes } from '../../enums';

function* loginSaga({ email, password }) {
  let accessToken;
  try {
    const { data } = yield api.auth.login(email, password);
    if (!data.status) {
      if (
        data.code === responseCodes.USER_NOT_EXISTS ||
        data.code === responseCodes.PASSWORD_NOT_MATCH
      ) {
        yield put(actions.auth.loginFailure(data.code));
      } else {
        yield put(actions.auth.loginFailure(responseCodes.SERVER_ERROR));
      }
      return;
    }
    ({ accessToken } = data.result);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const { data: dataUser } = yield api.user.getUserInfo();
    if (!dataUser.status) {
      yield put(actions.auth.loginFailure(responseCodes.SERVER_ERROR));
      return;
    }
    yield put(
      actions.auth.loginSuccess(
        accessToken,
        responseCodes.SUCCESS,
        dataUser.result,
      ),
    );
    setCookie('accessToken', accessToken, 14 * 24 * 60 * 60 * 1000);
  } catch (error) {
    yield put(actions.auth.loginFailure(responseCodes.SERVER_ERROR));
  }
}

function* verifyTokenSaga({ accessToken }) {
  try {
    const response = yield api.auth.verify(accessToken);
    if (!response?.status) throw new Error();

    const { accessToken: userToken } = response.result;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${userToken}`;

    const { status: getMeStatus, result: user } = yield api.account.getMe(
      accessToken,
    );
    if (!getMeStatus) throw new Error();

    setCookie('accessToken', userToken);
    yield put(actions.auth.verifyTokenSuccess(userToken, user));
  } catch (error) {
    yield put(actions.auth.verifyTokenFailure());
  }
}

function* logoutSaga() {
  try {
    const { data } = yield api.auth.logout();
    if (!data.status) throw new Error();
    setCookie('accessToken', null, 1);
  } catch (error) {
    // do nothing
  }
}

function* registerSaga({ idToken }) {
  try {
    const { data } = yield api.auth.register(idToken);
    if (!data.status) {
      if (data.code === responseCodes.USER_EXISTS) {
        yield put(actions.auth.registerFailure(responseCodes.USER_EXISTS));
      } else {
        yield put(actions.auth.registerFailure(responseCodes.SERVER_ERROR));
      }
      return;
    }
    yield put(actions.auth.registerSuccess(responseCodes.CREATE));
  } catch (error) {
    yield put(actions.auth.registerFailure(responseCodes.SERVER_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.auth.actionTypes.LOGIN, loginSaga),
    takeEvery(actions.auth.actionTypes.VERIFY_TOKEN, verifyTokenSaga),
    takeEvery(actions.auth.actionTypes.LOGOUT, logoutSaga),
    takeLatest(actions.auth.actionTypes.REGISTER, registerSaga),
  ]);
}
