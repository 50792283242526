import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import UnauthorizedPage from '../../containers/Unauthorized';

function PrivateRoute({ component: Component, ...rest }) {
  const { verifying, accessToken } = useSelector((state) => state.auth);
  const { keycloak } = useKeycloak();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) return <Component {...props} />;
        if (!accessToken && !verifying && keycloak.authenticated)
          return <UnauthorizedPage />;
        if (!accessToken && !verifying && !keycloak.token)
          return keycloak.login({ redirectUri: window.location.href });

        return null;
      }}
    />
  );
}

export default PrivateRoute;
