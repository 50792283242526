import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  TextField,
  Button,
  DialogActions,
  MenuItem,
  Avatar,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import useStyles, { StyledBotConfig } from './index.style';
import api from '../../../apis';
import {
  DOMAIN,
  LANGUAGE,
  DOMAIN_DEFAULT,
  BOT_MODE_DEFAULT,
} from '../../../constants';

const initialBot = {
  name: { value: '', error: false },
  language: { value: LANGUAGE.VI, error: false },
  description: { value: '', error: false },
  domain: { value: DOMAIN_DEFAULT },
  mode: { value: BOT_MODE_DEFAULT },
};

const BotConfig = ({ removeConfig, prebuiltBot, onBackToBotList }) => {
  const [data, setData] = useState(initialBot);
  const [importBotFile] = useState();
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateBot = async () => {
    setLoading(true);
    const { name, description, domain } = data;
    if (!name.value || !description.value) {
      setData({
        ...data,
        name: { ...data.name, error: !name.value },
        description: { ...data.description, error: !description.value },
      });
    } else {
      const { data: responseData } = await api.smartdialog.createBot({
        name: name.value,
        description: description.value,
        domain: domain.value,
        prebuiltBotId: prebuiltBot?.id,
      });
      if (!responseData?.status) {
        enqueueSnackbar(t('createBotError'), { variant: 'error' });
        return;
      }
      if (importBotFile) {
        const { data: importData } = await api.smartdialog.importBot({
          botId: responseData.results.id,
          importBotFile,
        });
        if (!importData.status) {
          enqueueSnackbar(t('importBotError'), { variant: 'error' });
        }
      }

      window.location.assign(
        `${process.env.REACT_APP_SMARTDIALOG_DASHBOARD_DOMAIN}/api-client/agent/${responseData?.result?.id}/dashboard`,
      );
    }
    setLoading(false);
  };

  const handleCancelModel = () => {
    removeConfig();
    setData(initialBot);
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: { value, error: !e.target.value } }));
  };

  // const handleFileSelected = async (e) => {
  //   const { files } = e.target;
  //   if (!files || files.length !== 1) {
  //     enqueueSnackbar(t('selectFileError'), { variant: 'error' });
  //     return;
  //   }
  //   setImportBotFile(files[0]);
  // };

  return (
    <StyledBotConfig>
      <Typography className="header">{t('createNewBot')}</Typography>
      {prebuiltBot?.id && (
        <div>
          <div className="pre-built-info">
            <Avatar variant="rounded" src={prebuiltBot?.logo} />
            <Typography className="name">{prebuiltBot?.name}</Typography>
          </div>
        </div>
      )}
      <Typography className="label">{t('name')}</Typography>
      <TextField
        name="name"
        className={classes.textField}
        autoFocus
        fullWidth
        required
        variant="outlined"
        error={data.name.error}
        helperText={data.name.error && t('required')}
        onChange={handleChangeData}
        placeholder={t('enterBotName')}
        disabled={loading}
      />
      <Typography className="label">{t('description')}</Typography>
      <TextField
        name="description"
        className={classes.textField}
        multiline
        rows={3}
        fullWidth
        required
        variant="outlined"
        error={data.description.error}
        helperText={data.description.error && t('required')}
        onChange={handleChangeData}
        placeholder={t('enterBotDescription')}
        disabled={loading}
      />
      <Typography className="label">{t('domain')}</Typography>
      <TextField
        name="domain"
        className={classes.textField}
        select
        value={data.domain.value}
        fullWidth
        variant="outlined"
        onChange={handleChangeData}
        disabled={loading}
      >
        {DOMAIN.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      <DialogActions>
        <Button
          className="back-button"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={onBackToBotList}
          disabled={loading}
        >
          {t('backToPrebuiltBotList')}
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={handleCancelModel}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
        {loading ? (
          <CircularProgress color="primary" size={30} />
        ) : (
          <Button color="primary" variant="contained" onClick={handleCreateBot}>
            {t('save')}
          </Button>
        )}
      </DialogActions>
    </StyledBotConfig>
  );
};

export default BotConfig;
