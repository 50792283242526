import React from 'react';
import { Divider } from '@material-ui/core';
import ListApp from './ListApp';
import ListBot from './ListBot';
import useStyles from './index.style';

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.dashboardWrapper}>
      <div className={classes.data}>
        <ListApp />
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <ListBot />
      </div>
    </div>
  );
};

export default Dashboard;
