import { useState, useEffect } from 'react';
import { getUsers } from '../../../apis/user';

const useFetchData = ({ search, page, limit, userId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  async function fetchDataList() {
    const offset = page * limit;
    setLoading(true);
    const { data: responseData } = await getUsers({
      search,
      offset,
      limit,
    });
    setLoading(false);
    if (responseData.status) {
      setData({
        results: responseData.result.users,
        count: responseData.result.metadata.total,
      });
    } else {
      setData({ count: 0, results: [] });
    }
  }

  useEffect(() => {
    fetchDataList();
  }, [search, page, limit, userId]);

  return [loading, data];
};

export default useFetchData;
