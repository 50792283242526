/* eslint-disable no-param-reassign */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { Tooltip, Avatar, Typography } from '@material-ui/core';
import api from '../../../apis';
import NoPermission from '../../../components/NoPermission';
import useStyles from './index.style';
import { changeAlias } from '../../../utils/string';

const AdminApps = () => {
  const { isAdmin } = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const fetchData = async ({ search, offset, limit }) => {
    const {
      data,
      config: { params },
    } = await api.livechat.getListApps({
      search,
      offset,
      limit,
    });
    if (data.status) {
      const dataConvert = data.results.apps.map((app) => {
        app.channels = [];
        if (app.facebook) app.channels = [...app.channels, 'facebook'];
        if (app.zalo) app.channels = [...app.channels, 'zalo'];
        if (app.viber) app.channels = [...app.channels, 'viber'];
        if (app.aicc) app.channels = [...app.channels, 'aicc'];
        return app;
      });
      return {
        apps: dataConvert,
        total: data.results.metadata.total,
        page: params.offset,
      };
    }
    return {};
  };

  const columns = [
    {
      title: `${t('name')}`,
      field: 'name',
      render: (record) => {
        const color = Math.floor(Math.random() * 3);
        return (
          <div className={classes.name}>
            <Avatar
              variant="rounded"
              src={record.avatar}
              className={`${classes.logo} ${color === 0 && classes.yellow}
            ${color === 1 && classes.pink} ${color === 2 && classes.blue}`}
            >
              {!record.avatar && changeAlias(record.name).slice(0, 1)}
            </Avatar>
            <span>{record.name}</span>
          </div>
        );
      },
    },
    {
      title: `${t('bot')}`,
      field: 'botName',
    },
    {
      title: `${t('messagingChannel')}`,
      field: 'channels',
      render: (record) => (
        <div className={classes.messagingChannel}>
          {record.channels.includes('facebook') && (
            <Tooltip placement="bottom" title={t('facebook')}>
              <Avatar
                className={classes.channelLogo}
                src="/images/facebook-icon.svg"
              />
            </Tooltip>
          )}
          {record.channels.includes('zalo') && (
            <Tooltip
              className={classes.channelLogo}
              placement="bottom"
              title={t('zalo')}
            >
              <Avatar
                className={classes.channelLogo}
                src="/images/zalo-icon.svg"
              />
            </Tooltip>
          )}
          {record.channels.includes('viber') && (
            <Tooltip placement="bottom" title={t('viber')}>
              <Avatar
                className={classes.channelLogo}
                src="/images/viber-icon.svg"
              />
            </Tooltip>
          )}
          {record.channels.includes('aicc') && (
            <Tooltip placement="bottom" title={t('aicc')}>
              <Avatar
                className={classes.channelLogo}
                src="/images/aicc-icon.svg"
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: `${t('replyMode')}`,
      field: 'replyMode',
    },
  ];

  return (
    <>
      {!isAdmin ? (
        <NoPermission
          backText={t('backToDashboard')}
          handleBack={() => history.push('/dashboard')}
        />
      ) : (
        <MaterialTable
          style={{ padding: '10px 15px 0px 15px' }}
          title={<Typography variant="h5">{t('listApplication')}</Typography>}
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              fetchData({
                search: query.search,
                offset: query.page,
                limit: query.pageSize,
              }).then((results) => {
                resolve({
                  data: results.apps,
                  totalCount: results.total,
                  page: results.page,
                });
              });
            })
          }
          localization={{
            body: {
              emptyDataSourceMessage: t('noData'),
            },
            pagination: {
              labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
              labelRowsSelect: t('rows'),
              labelRowsPerPage: t('rowPerPage'),
              firstAriaLabel: t('firstPage'),
              firstTooltip: t('firstPage'),
              previousAriaLabel: t('prePage'),
              previousTooltip: t('prePage'),
              nextAriaLabel: t('nextPage'),
              nextTooltip: t('nextPage'),
              lastAriaLabel: t('lastPage'),
              lastTooltip: t('lastPage'),
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search'),
            },
          }}
          options={{
            sorting: false,
            pageSize: 10,
          }}
        />
      )}
    </>
  );
};

export default AdminApps;
