import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border: 1px solid #000034;
    }
    &:hover fieldset {
      border: 1px solid #000034;
    }
    &.Mui-focused fieldset {
      border: 1px solid #000034;
    }
  }
`;
