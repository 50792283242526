import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MaterialTable from 'material-table';
import { Typography, Avatar } from '@material-ui/core';
import api from '../../../apis';
import NoPermission from '../../../components/NoPermission';
import useStyles from './index.style';
import { changeAlias } from '../../../utils/string';

const AdminApps = () => {
  const { isAdmin } = useSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const fetchData = async ({ search, offset, limit }) => {
    const {
      data,
      config: { params },
    } = await api.smartdialog.getListBots({
      search,
      offset,
      limit,
    });
    if (data.status) {
      return {
        bots: data.results.agents,
        total: data.results.metadata.total,
        page: params.offset,
      };
    }
    return {};
  };

  const columns = [
    {
      title: `${t('name')}`,
      field: 'name',
      render: (record) => {
        const color = Math.floor(Math.random() * 3);
        return (
          <div className={classes.name}>
            <Avatar
              variant="rounded"
              src={record.avatar}
              className={`${classes.logo} ${color === 0 && classes.yellow}
            ${color === 1 && classes.pink} ${color === 2 && classes.blue}`}
            >
              {!record.avatar && changeAlias(record.name).slice(0, 1)}
            </Avatar>
            <span>{record.name}</span>
          </div>
        );
      },
    },
    {
      title: `${t('intent')}`,
      field: 'numberOfIntents',
      render: (record) => record.numberOfIntents.toLocaleString(),
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: `${t('userSay')}`,
      field: 'numberOfUserSays',
      render: (record) => record.numberOfUserSays.toLocaleString(),
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: `${t('action')}`,
      field: 'numberOfActions',
      render: (record) => record.numberOfActions.toLocaleString(),
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
    {
      title: `${t('lastTrain')}`,
      field: 'lastTrain',
      render: (record) => <>{record.lastTrain && record.lastTrain}</>,
      headerStyle: {
        textAlign: 'right',
      },
      cellStyle: {
        textAlign: 'right',
      },
    },
  ];

  return (
    <>
      {!isAdmin ? (
        <NoPermission
          backText={t('backToDashboard')}
          handleBack={() => history.push('/dashboard')}
        />
      ) : (
        <MaterialTable
          style={{ padding: '10px 15px 0px 15px' }}
          title={<Typography variant="h5">{t('listBot')}</Typography>}
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              fetchData({
                search: query.search,
                offset: query.page,
                limit: query.pageSize,
              }).then((results) => {
                resolve({
                  data: results.bots,
                  totalCount: results.total,
                  page: results.page,
                });
              });
            })
          }
          localization={{
            body: {
              emptyDataSourceMessage: t('noData'),
            },
            pagination: {
              labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
              labelRowsSelect: t('rows'),
              labelRowsPerPage: t('rowPerPage'),
              firstAriaLabel: t('firstPage'),
              firstTooltip: t('firstPage'),
              previousAriaLabel: t('prePage'),
              previousTooltip: t('prePage'),
              nextAriaLabel: t('nextPage'),
              nextTooltip: t('nextPage'),
              lastAriaLabel: t('lastPage'),
              lastTooltip: t('lastPage'),
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search'),
            },
          }}
          options={{
            sorting: false,
            pageSize: 10,
          }}
        />
      )}
    </>
  );
};

export default AdminApps;
