import { actionTypes } from './actions';
import { randomAvatarColor } from '../../configs/styleConstant';

export const initialState = {
  accessToken: null,
  verifying: false,
  permissions: [],
  isFetchingPermissions: false,
  loginCode: null,
  isProcessing: false,
  user: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN: {
      return { ...state, isProcessing: true };
    }
    case actionTypes.LOGIN_SUCCESS: {
      const { accessToken, code, user } = action;
      return {
        ...state,
        accessToken,
        loginCode: code,
        isProcessing: false,
        user: {
          ...user,
          bgColor: randomAvatarColor(),
        },
      };
    }
    case actionTypes.LOGIN_FAILURE: {
      const { code } = action;
      return { ...state, loginCode: code, isProcessing: false };
    }
    case actionTypes.VERIFY_TOKEN:
      return { ...state, verifying: true };
    case actionTypes.VERIFY_TOKEN_SUCCESS: {
      const { accessToken, user } = action;
      return {
        ...state,
        verifying: false,
        accessToken,
        user: {
          ...user,
          bgColor: randomAvatarColor(),
        },
      };
    }
    case actionTypes.VERIFY_TOKEN_FAILURE:
      return { ...state, verifying: false };
    case actionTypes.LOGOUT:
      return {
        ...state,
        accessToken: null,
        isFetchingPermissions: false,
        permissions: [],
        loginCode: null,
        user: null,
      };
    case actionTypes.GET_PERMISSIONS_BY_ROLE:
      return { ...state, isFetchingPermissions: true };
    case actionTypes.GET_PERMISSIONS_BY_ROLE_SUCCESS: {
      const { permissions } = action;
      return { ...state, isFetchingPermissions: false, permissions };
    }
    case actionTypes.GET_PERMISSIONS_BY_ROLE_FAIL: {
      return { ...state, isFetchingPermissions: false };
    }
    case actionTypes.REGISTER: {
      return { ...state, isProcessing: true };
    }
    case actionTypes.REGISTER_SUCCESS: {
      const { code } = action;
      return { ...state, loginCode: code, isProcessing: false };
    }
    case actionTypes.REGISTER_FAILURE: {
      const { code } = action;
      return { ...state, loginCode: code, isProcessing: false };
    }
    case actionTypes.UPDATE_USER: {
      const { user } = action;
      return {
        ...state,
        user: {
          ...user,
          bgColor: state.user.bgColor,
        },
      };
    }
    default:
      return state;
  }
}
