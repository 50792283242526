const LANGUAGE = { EN: 'en', VI: 'vi' };

const DOMAIN = [
  { key: 'general', name: 'Chung' },
  { key: 'debt', name: 'Nhắc nợ' },
  { key: 'kyc', name: 'KYC' },
  { key: 'qleads', name: 'QLeads' },
  { key: 'booking', name: 'Đặt vé' },
];

const DOMAIN_DEFAULT = 'general';
const BOT_MODE_DEFAULT = 'VOICE_BOT';

const PHASE = { TEST: 'TEST', POC: 'POC', PILOT: 'PILOT', PROD: 'PROD' };

export { LANGUAGE, DOMAIN, DOMAIN_DEFAULT, BOT_MODE_DEFAULT, PHASE };
