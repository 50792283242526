import axiosInstance from './axiosInstance';
import { CALLBOT_ACCOUNT_URL } from '../configs';

export async function login(email, password) {
  const response = await axiosInstance({
    method: 'POST',
    url: '/auths/login',
    data: { email, password },
  });
  return response;
}

export async function register({ name, email, password }) {
  const response = await axiosInstance({
    method: 'POST',
    url: 'auths/register',
    data: {
      name,
      email,
      password,
    },
  });
  return response;
}

export async function verify(accessToken) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${CALLBOT_ACCOUNT_URL}/api/v1/auths/verify`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response?.data;
  } catch (error) {
    return error.response;
  }
}

export async function logout() {
  const response = await axiosInstance({
    method: 'POST',
    url: '/auths/logout',
  });
  return response;
}

export async function changePassword({ password, newPassword }) {
  const response = await axiosInstance({
    method: 'PUT',
    url: '/auths/changePassword',
    data: {
      password,
      newPassword,
    },
  });
  return response;
}
