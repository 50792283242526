import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: theme.borderRadius,
    boxShadow: theme.boxShadow,
    backgroundColor: theme.palette.background.paper,
    padding: (props) => props.padding || 0,
    margin: (props) => props.margin || '0 0 32px 0',
    justifyContent: (props) => props.justifyContent || 'flex-start',
    flexDirection: (props) => props.flexDirection || 'row',
  },
}));
