import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  TextField,
  Button,
  DialogActions,
  Typography,
  MenuItem,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { PHASE } from '../../../constants';
import { CALLBOT_CRM_URL } from '../../../configs';
import api from '../../../apis';
import { StyledAppConfig } from './index.style';

const initialApp = {
  name: { value: '', error: false },
  phase: { value: PHASE.TEST, error: false },
  description: { value: '', error: false },
};

const AppConfig = ({ selectedPrebuiltBot, onToFirstStep, onRemoveConfig }) => {
  const [formData, setFormData] = useState(initialApp);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateApp = async () => {
    setLoading(true);
    const { name, phase, description } = formData;
    if (!name?.value || !description?.value) {
      setFormData({
        ...formData,
        name: { ...formData.name, error: !name.value },
        description: { ...formData.description, error: !description.value },
      });
    } else {
      const { data } = await api.livechat.createApp({
        name: name.value,
        phase: phase.value,
        description: description.value,
        prebuiltBotId: selectedPrebuiltBot?.id,
      });
      if (data?.status) {
        window.location.assign(
          `${CALLBOT_CRM_URL}/apps/${data.result.id}/setting`,
        );
      } else {
        enqueueSnackbar(t('createAppError'), { variant: 'error' });
      }
    }
    setLoading(false);
  };

  const handleCancelModel = () => {
    setFormData();
    onRemoveConfig();
  };

  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: { value, error: !value } }));
  };

  return (
    <StyledAppConfig>
      <Typography className="header">{t('createAppSecondStep')}</Typography>
      <Typography className="label">{t('name')}</Typography>
      <TextField
        name="name"
        autoFocus
        label={t('name')}
        fullWidth
        required
        variant="outlined"
        error={formData?.name?.error}
        helperText={formData?.name?.error && t('required')}
        onChange={handleChangeFormData}
        disabled={loading}
      />
      <Typography className="label">{t('phase')}</Typography>
      <TextField
        name="phase"
        select
        value={formData.phase.value}
        fullWidth
        variant="outlined"
        onChange={handleChangeFormData}
        disabled={loading}
      >
        {Object.entries(PHASE).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>

      <Typography className="label">{t('description')}</Typography>
      <TextField
        name="description"
        fullWidth
        multiline
        minRows={3}
        required
        variant="outlined"
        error={formData?.description?.error}
        helperText={formData?.description?.error && t('required')}
        onChange={handleChangeFormData}
        disabled={loading}
      />
      {selectedPrebuiltBot?.id ? (
        <div>
          <Typography className="label">{t('integratedBot')}</Typography>
          <div className="pre-built-info">
            <Avatar variant="rounded" src={selectedPrebuiltBot?.logo} />
            <Typography className="name">
              {selectedPrebuiltBot?.name || ''}
            </Typography>
          </div>
        </div>
      ) : (
        <Typography className="warning-text">
          {t('noIntegratedBOTYet')}
        </Typography>
      )}
      <DialogActions>
        <Button
          className="back-button"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={onToFirstStep}
          disabled={loading}
        >
          {t('backToFirstStep')}
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={handleCancelModel}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
        {loading ? (
          <CircularProgress color="primary" size={30} />
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCreateApp}
            >
              {t('save')}
            </Button>
          </>
        )}
      </DialogActions>
    </StyledAppConfig>
  );
};

export default AppConfig;
