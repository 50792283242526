import React, { useState } from 'react';

import { Typography, Avatar, Tabs, Tab } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import ActionIcon from '../../static/img/action-icon.svg';
import EntityIcon from '../../static/img/entity-icon.svg';
import IntentIcon from '../../static/img/intent-icon.svg';
import UserSayIcon from '../../static/img/user-say-icon.svg';

import CustomAudio from '../CustomAudio';
import { StyledBotDetail, StyledPrebuiltBotTabs } from './index.style';

const TAB = {
  FEATURES: 0,
  SAMPLES: 1,
};

const PrebuiltBotTabs = ({ samples = [], features = [] }) => {
  const [tab, setTab] = useState(TAB.FEATURES);
  const { t } = useTranslation();
  const handleTabChange = (event, value) => setTab(value);

  return (
    <StyledPrebuiltBotTabs>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab className="tab" label={t('feature')} />
        <Tab className="tab" label={t('sampleConversation')} />
      </Tabs>
      {tab === TAB.FEATURES ? (
        <div className="features">
          {features?.map((feature) => (
            <div className="feature" key={feature}>
              <Check className="check" />
              <Typography className="content">{feature}</Typography>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {samples?.map((sample, index) => (
            <div className="samples" key={sample.name}>
              <Typography className="name">
                {`${t('script')} ${index + 1}: ${sample?.name}`}
              </Typography>
              <CustomAudio src={sample?.audio} />
            </div>
          ))}
        </div>
      )}
    </StyledPrebuiltBotTabs>
  );
};

const PreBuiltBotDetail = ({ bot }) => {
  const { t } = useTranslation();

  return (
    <StyledBotDetail>
      <Typography className="header"> {`${t('prebuiltBotDetail')}`}</Typography>
      <div className="basic-info">
        <Avatar variant="rounded" src={bot?.logo} />
        <Typography className="name">{bot?.name}</Typography>
      </div>
      <div className="model-info">
        <div>
          <Typography className="title">{t('intent')}</Typography>
          <div className="info">
            <img src={IntentIcon} alt="IntentIcon" />
            <Typography className="value">{bot?.numberOfIntents}</Typography>
          </div>
        </div>
        <div>
          <Typography className="title">{t('action')}</Typography>
          <div className="info">
            <img src={ActionIcon} alt="ActionIcon" />
            <Typography className="value">{bot?.numberOfActions}</Typography>
          </div>
        </div>
        <div>
          <Typography className="title">{t('entity')}</Typography>
          <div className="info">
            <img src={EntityIcon} alt="EntityIcon" />
            <Typography className="value">{bot?.numberOfEntities}</Typography>
          </div>
        </div>
        <div>
          <Typography className="title">{t('userSay')}</Typography>
          <div className="info">
            <img src={UserSayIcon} alt="UserSayIcon" />
            <Typography className="value">{bot?.numberOfUserSays}</Typography>
          </div>
        </div>
      </div>
      <div className="description">
        <Typography className="title">{t('description')}</Typography>
        <Typography>{bot?.description}</Typography>
      </div>
      <div className="sample-conversation">
        <PrebuiltBotTabs samples={bot?.samples} features={bot?.features} />
      </div>
    </StyledBotDetail>
  );
};

export default PreBuiltBotDetail;
