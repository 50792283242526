import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { StyledTextField } from './index.style';

const NoData = ({ text }) => (
  <StyledTextField
    value={text}
    variant="outlined"
    fullWidth
    InputProps={{
      readOnly: true,
      startAdornment: (
        <InputAdornment position="start">
          <Info color="primary" />
        </InputAdornment>
      ),
    }}
  />
);

export default NoData;
