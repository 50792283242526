import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import ComponentPrebuiltBotDetail from '../../../components/PrebuiltBotDetail';

import { StyledPrebuiltBotDetail } from './index.style';
import apis from '../../../apis';

const PrebuiltBotDetail = ({
  detailPrebuiltBotId,
  onToFirstStep,
  onUsePrebuiltBot,
}) => {
  const [prebuiltBot, setPrebuiltBot] = useState({});
  const { t } = useTranslation();

  const fetchPrebuiltBot = async () => {
    const response = await apis.smartdialog.getPreBuiltBotById(
      detailPrebuiltBotId,
    );
    if (response?.data?.status) setPrebuiltBot(response?.data?.result);
  };

  useEffect(() => {
    fetchPrebuiltBot();
  }, [detailPrebuiltBotId]);

  return (
    <StyledPrebuiltBotDetail>
      <ComponentPrebuiltBotDetail bot={prebuiltBot} />
      <div className="action">
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={onToFirstStep}
        >
          {t('backToFirstStep')}
        </Button>
        <Button
          variant="contained"
          className="useNow"
          onClick={() => onUsePrebuiltBot(prebuiltBot)}
        >
          {t('useNow')}
        </Button>
      </div>
    </StyledPrebuiltBotDetail>
  );
};

export default PrebuiltBotDetail;
