import React, { useEffect, useState } from 'react';

import {
  Typography,
  Grid,
  TextField,
  Avatar,
  Box,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  StyledPreBuildBotList,
  StyledPrebuiltBot,
  StyledCreateButton,
} from './index.style';
import apis from '../../../apis';

const PrebuiltBot = ({ bot, onHandleViewBot, onHandleUseBot }) => {
  const [showAction, setShowAction] = useState(false);
  const { t } = useTranslation();
  return (
    <StyledPrebuiltBot
      onMouseEnter={() => setShowAction(true)}
      onMouseLeave={() => setShowAction(false)}
    >
      <Box
        className="bot-header clickable"
        onClick={() => onHandleViewBot(bot.id)}
      >
        <Typography className="name">{bot?.name}</Typography>
        <Avatar variant="rounded" src={bot?.logo} />
      </Box>
      <Typography
        className="description clickable"
        onClick={() => onHandleViewBot(bot.id)}
      >{`${bot?.description}`}</Typography>
      {showAction && (
        <div className="action">
          <Button
            className="view-detail"
            onClick={() => onHandleViewBot(bot.id)}
          >
            {t('viewDetail')}
          </Button>
          <Button
            className="use-now"
            onClick={() =>
              onHandleUseBot({
                id: bot.id,
                name: bot.name,
                logo: bot.logo,
              })
            }
          >
            {t('useNow')}
          </Button>
        </div>
      )}
    </StyledPrebuiltBot>
  );
};

const PreBuiltBotList = ({
  createEmptyBot,
  onHandleViewBot,
  onHandleUseBot,
}) => {
  const [bots, setBots] = useState([]);
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const fetchPreBuiltBots = async () => {
    const response = await apis.smartdialog.getPreBuiltBots({
      search,
    });
    if (response?.data?.status) setBots(response?.data?.result.prebuiltBots);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    fetchPreBuiltBots();
  }, [search]);

  return (
    <StyledPreBuildBotList>
      <div className="header">
        <div>
          <Typography className="title">{t('createNewBOT')}</Typography>
          <Typography className="subtitle">
            {t('createNewBOTSubTitle')}
          </Typography>
        </div>
        <TextField
          fullWidth
          autoFocus
          variant="outlined"
          placeholder={t('enterBotNameToSearch')}
          value={search}
          onChange={handleChangeSearch}
          className="filter"
        />
      </div>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid item xs={4} spacing={3} onClick={createEmptyBot}>
          <Box height="100%" display="flex">
            <StyledCreateButton fullWidth>
              {t('createEmptyBot')}
            </StyledCreateButton>
          </Box>
        </Grid>
        {bots?.map((bot) => (
          <Grid item xs={4} spacing={3}>
            <PrebuiltBot
              bot={bot}
              onHandleViewBot={onHandleViewBot}
              onHandleUseBot={onHandleUseBot}
            />
          </Grid>
        ))}
      </Grid>
    </StyledPreBuildBotList>
  );
};

export default PreBuiltBotList;
