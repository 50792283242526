import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { COLOR, TRANSPARENT_COLOR } from '../../../styles/color';
import { mainColors } from '../../../configs/styleConstant';

export default makeStyles((theme) => ({
  listAppWrapper: {
    display: 'grid',
    gridGap: '10px',
  },
  appHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  search: {
    width: '300px',
  },
  appList: {
    textTransform: 'uppercase',
  },
  item: {
    padding: '16px',
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  logo: {
    marginRight: '10px',
    width: '50px',
    height: '50px',
    alignSelf: 'center',
    textTransform: 'uppercase',
  },
  appAndBot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  botName: {
    '&:hover': {
      color: mainColors.havelockBlue,
    },
  },
  linkToBot: {
    fontStyle: 'italic',
    fontWeight: '600',
    display: 'flex',
  },
  appData: {
    flexGrow: 1,
    alignSelf: 'center',
  },
  appName: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  messagingChannel: {
    display: 'flex',
    alignItems: 'center',
  },
  channelLogo: {
    width: '20px',
    height: '20px',
    marginLeft: '10px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
  yellow: {
    backgroundColor: theme.palette.card.primary,
  },
  pink: {
    backgroundColor: theme.palette.card.secondary,
  },
  blue: {
    backgroundColor: theme.palette.card.tertiary,
  },
  noData: {
    marginLeft: 8,
    fontStyle: 'italic',
  },
}));

export const StyledIntegrateBot = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
    }

    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .filter {
      margin: auto 0;
      max-width: 460px;
    }
  }

  .forward-button {
    margin-left: auto;
  }

  .arrow-forward {
    color: ${COLOR.primary};
  }
`;

export const StyledAppConfig = styled('div')`
  .header {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
  }

  .pre-built-info {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: ${COLOR.lightBlue};

    .name {
      font-weight: 700;
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .back-button {
    margin-right: auto;
  }

  .forawrd-button {
    margin-left: auto;
  }

  .label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  .form {
    display: grid;
    grid-template-columns: 18fr 1fr;
    align-items: center;
  }

  .form-text {
    padding: 3px 0;
  }

  .warning-text {
    font-weight: 600;
    font-size: 14px;
    color: ${COLOR.red};
    margin: 16px 0 20px 0;
  }
`;

export const StyledPrebuiltBot = styled('div')`
  border: 1px solid #e8ebf3;
  border-radius: 6px;
  padding: 22px 16px;
  position: relative;
  height: 150px;

  .bot-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .name {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-align: justify;
    }
  }

  .action {
    position: absolute;
    bottom: 0px;
    top: 60%;
    left: 0px;
    right: 0px;
    background-image: linear-gradient(to top, ${COLOR.light}, ${COLOR.white});

    .view-detail {
      position: absolute;
      bottom: 16px;
      left: 16px;
      right: 55%;
      border: 1px solid #e8ebf3;
      border-radius: 6px;
      color: ${COLOR.headerTableText};
      background-color: ${COLOR.white};

      :hover {
        opacity: 0.8;
        box-shadow: 0 5px 5px 0 ${TRANSPARENT_COLOR.dark};
      }
    }

    .use-now {
      position: absolute;
      bottom: 16px;
      right: 16px;
      left: 55%;
      border-radius: 6px;
      color: ${COLOR.white};
      background-color: ${COLOR.primary};

      :hover {
        opacity: 0.8;
        box-shadow: 0 5px 5px 0 ${TRANSPARENT_COLOR.dark};
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

export const StyledPrebuiltBotDetail = styled.div`
  .action {
    display: flex;
    justify-content: space-between;

    .useNow {
      color: ${COLOR.white};
      background-color: ${COLOR.primary};
    }
  }
`;
