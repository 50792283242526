import React, { useState, useRef } from 'react';
import { VolumeUp, VolumeOff } from '@material-ui/icons';

const CustomAudio = ({ src }) => {
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef();

  const handleClickPlayAudio = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setPlaying(!playing);
  };

  const handleAudioEnd = () => setPlaying(false);

  return (
    <>
      {playing ? (
        <VolumeUp onClick={handleClickPlayAudio} />
      ) : (
        <VolumeOff onClick={handleClickPlayAudio} />
      )}
      <audio ref={audioRef} src={src} onEnded={handleAudioEnd}>
        <track kind="captions" />
      </audio>
    </>
  );
};

export default CustomAudio;
