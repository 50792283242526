import React, { useState, useEffect, createContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { People, Reddit, SettingsInputComponent } from '@material-ui/icons';
import useStyles from './index.style';
import Header from './MainAppBar';
import Sidebar from './Sidebar';
import Content from './Content';

export const AppContext = createContext();

const menu = [
  {
    heading: 'accounts',
    icon: <People />,
    route: `/admin/accounts`,
  },
  {
    heading: 'apps',
    icon: <SettingsInputComponent />,
    route: `/admin/apps`,
  },
  {
    heading: 'bots',
    icon: <Reddit />,
    route: `/admin/bots`,
  },
];

const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const { accessToken, user } = useSelector((state) => state.auth);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(true);
  const [displaySideBar, setDisplaySideBar] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarToggle = () => {
    setOpenSideBar((open) => !open);
  };

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      setDisplaySideBar(true);
    } else {
      setDisplaySideBar(false);
    }
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <Header
        accessToken={accessToken}
        user={user}
        bgColor={user.bgColor}
        displaySideBar={displaySideBar}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        menu={menu}
        mobileOpen={mobileOpen}
        openSideBar={openSideBar}
        displaySideBar={displaySideBar}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Content
        displaySideBar={displaySideBar}
        handleSidebarToggle={handleSidebarToggle}
      >
        {children}
      </Content>
    </div>
  );
};

export default Layout;
