export const actionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  VERIFY_TOKEN: 'VERIFY_TOKEN',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',
  GET_PERMISSIONS_BY_ROLE: 'GET_PERMISSIONS_BY_ROLE',
  GET_PERMISSIONS_BY_ROLE_SUCCESS: 'GET_PERMISSIONS_BY_ROLE_SUCCESS',
  GET_PERMISSIONS_BY_ROLE_FAIL: 'GET_PERMISSIONS_BY_ROLE_FAIL',
  REGISTER: 'REGISTER',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  UPDATE_USER: 'UPDATE_USER',
};

export function login(email, password) {
  return {
    type: actionTypes.LOGIN,
    email,
    password,
  };
}

export function loginSuccess(accessToken, code, user) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    accessToken,
    code,
    user,
  };
}

export function loginFailure(code) {
  return {
    type: actionTypes.LOGIN_FAILURE,
    code,
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  };
}

export function verifyToken(accessToken) {
  return {
    type: actionTypes.VERIFY_TOKEN,
    accessToken,
  };
}

export function verifyTokenSuccess(accessToken, user) {
  return {
    type: actionTypes.VERIFY_TOKEN_SUCCESS,
    accessToken,
    user,
  };
}

export function verifyTokenFailure() {
  return {
    type: actionTypes.VERIFY_TOKEN_FAILURE,
  };
}

export function refreshLoginPage() {
  return {
    type: actionTypes.REFRESH_LOGIN_PAGE,
  };
}

export function getPermissionsByRole() {
  return {
    type: actionTypes.GET_PERMISSIONS_BY_ROLE,
  };
}

export function getPermissionsByRoleSuccess(permissions) {
  return {
    type: actionTypes.GET_PERMISSIONS_BY_ROLE_SUCCESS,
    permissions,
  };
}

export function getPermissionsByRoleFail() {
  return {
    type: actionTypes.GET_PERMISSIONS_BY_ROLE_FAIL,
  };
}

export function register(idToken) {
  return {
    type: actionTypes.REGISTER,
    idToken,
  };
}

export function registerSuccess(code) {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    code,
  };
}

export function registerFailure(code) {
  return {
    type: actionTypes.REGISTER_FAILURE,
    code,
  };
}

export function updateUser(user) {
  return {
    type: actionTypes.UPDATE_USER,
    user,
  };
}
