import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import unauthorizedImg from '../../assets/images/unauthorized.png';
import actions from '../../redux/actions';
import { StyledUnauthorized } from './index.style';

const Unauthorized = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(actions.auth.logout());
    keycloak.logout();
  };

  return (
    <StyledUnauthorized>
      <Typography className="unauthorized-title center">
        {t('unauthorizedTitle')}
      </Typography>
      <Typography className="center">{t('unauthorizedDescription')}</Typography>
      <Button onClick={handleLogout} variant="contained">
        {t('logout')}
      </Button>
      <img
        src={unauthorizedImg}
        alt="unauthorized"
        className="unauthorized-img"
      />
    </StyledUnauthorized>
  );
};

export default Unauthorized;
