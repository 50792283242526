import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import PreBuiltBotList from './PreBuiltBotList';
import BotConfig from './BotConfig';
import PreBuiltBotDetail from './PreBuiltBotDetail';

const PAGE = {
  PRE_BUILT_BOT_LIST: 'PRE_BUILT_BOT_LIST',
  PRE_BUILT_BOT_DETAIL: 'PRE_BUILT_BOT_DETAIL',
  BOT_CONFIG: 'BOT_CONFIG',
};

const CreateBotDialog = ({ visibleBotModel, setVisibleBot }) => {
  const [curPage, setCurPage] = useState(PAGE.PRE_BUILT_BOT_LIST);
  const [detailBot, setDetailBot] = useState('');
  const [selectedBot, setSelectedBot] = useState({});

  const handleClose = () => {
    setCurPage(PAGE.PRE_BUILT_BOT_LIST);
    setVisibleBot();
    setSelectedBot({});
  };

  const handleCreateEmptyBot = () => {
    setCurPage(PAGE.BOT_CONFIG);
  };

  const handleRemoveConfig = () => {
    setCurPage(PAGE.PRE_BUILT_BOT_LIST);
    setSelectedBot({});
    setDetailBot('');
    setVisibleBot();
  };

  const handleSelectDetailBot = (botId) => {
    setDetailBot(botId);
    setCurPage(PAGE.PRE_BUILT_BOT_DETAIL);
  };

  const handleBackToBotsList = () => {
    setCurPage(PAGE.PRE_BUILT_BOT_LIST);
    setDetailBot('');
    setSelectedBot({});
  };

  const handleSetSelectedBot = (prebuiltBot) => {
    setSelectedBot(prebuiltBot);
    setCurPage(PAGE.BOT_CONFIG);
  };

  const CreateBotContent = () => {
    switch (curPage) {
      case PAGE.PRE_BUILT_BOT_LIST:
        return (
          <PreBuiltBotList
            createEmptyBot={handleCreateEmptyBot}
            onHandleViewBot={handleSelectDetailBot}
            onHandleUseBot={handleSetSelectedBot}
          />
        );
      case PAGE.PRE_BUILT_BOT_DETAIL:
        return (
          <PreBuiltBotDetail
            prebuiltBotId={detailBot}
            backToBotsList={handleBackToBotsList}
            setSelectedBot={handleSetSelectedBot}
          />
        );
      case PAGE.BOT_CONFIG:
        return (
          <BotConfig
            removeConfig={handleRemoveConfig}
            prebuiltBot={selectedBot}
            onBackToBotList={handleBackToBotsList}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={visibleBotModel}
      onClose={handleClose}
    >
      <DialogContent>
        <CreateBotContent />
      </DialogContent>
    </Dialog>
  );
};

export default CreateBotDialog;
