import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { deleteUser } from '../../../../apis/user';

function DeleteAccountDialog({
  open,
  handleClose,
  account,
  handleDeleteAccountSuccess,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteAccount = async () => {
    const { data } = await deleteUser(account.id);
    if (!data.status) {
      enqueueSnackbar(data.message, {
        variant: 'error',
      });
    } else {
      handleDeleteAccountSuccess(account.position, account.id);
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('areYouSure')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${t('deleteAccount')}${account.email}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button color="primary" onClick={handleDeleteAccount}>
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAccountDialog;
