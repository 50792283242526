const mainColors = {
  buttercup: '#f6a61f',
  froly: '#f16a73',
  havelockBlue: '#4991e2',
};

const cyanShadow =
  '0 12px 20px -10px rgba(73, 145, 226, 0.28), 0 4px 20px 0 rgba(0,0,0,0.12), 0 7px 8px -5px rgba(73, 145, 226, 0.2)';

const bgAvatarColors = [
  mainColors.buttercup,
  mainColors.froly,
  mainColors.havelockBlue,
];

const randomAvatarColor = () => {
  const rdIndex = Math.floor(Math.random() * bgAvatarColors.length);
  const color = bgAvatarColors[rdIndex];
  return color;
};

export { mainColors, randomAvatarColor, cyanShadow };
