import React, { useState, useEffect } from 'react';
import {
  TablePagination,
  IconButton,
  InputBase,
  makeStyles,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../utils/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  input: {
    textAlign: 'center',
  },
  pageNumber: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    lineHeight: '1.1875em',
    fontSize: '1rem',
    letterSpacing: '0.00938em',
  },
}));

function ActionsComponent({
  count,
  page,
  rowsPerPage,
  backIconButtonProps,
  nextIconButtonProps,
  onChangePage,
}) {
  const classes = useStyles();
  const [inputPage, setInputPage] = useState(page + 1);
  const [debouncedInputPage] = useDebounce(inputPage, 800);

  const maxPage = Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  useEffect(() => {
    if (page + 1 !== parseInt(inputPage, 10)) {
      setInputPage(page + 1);
    }
  }, [page]);

  useEffect(() => {
    if (debouncedInputPage && debouncedInputPage - 1 !== page) {
      let newPage = Number(debouncedInputPage)
        ? Number(debouncedInputPage) - 1
        : 0;
      if (newPage > maxPage) newPage = maxPage;
      onChangePage(null, newPage);
    }
  }, [debouncedInputPage]);

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const onChangeInputPage = (event) => {
    setInputPage(event.target.value);
  };

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, maxPage);
  };

  const disableBack = page === 0;
  const disableNext = page >= maxPage;

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={disableBack}
        aria-label="first page"
        title="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={disableBack}
        {...backIconButtonProps}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <InputBase
        classes={{
          input: classes.input,
        }}
        value={inputPage}
        inputProps={{
          size: '1',
        }}
        onChange={onChangeInputPage}
      />
      <span className={classes.pageNumber}>/ {maxPage + 1}</span>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={disableNext}
        {...nextIconButtonProps}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={disableNext}
        aria-label="last page"
        title="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
function CustomTablePagination({ showInputPage, ...props }) {
  const { t } = useTranslation();
  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} ${t('of')} ${count}`;

  if (!showInputPage) return <TablePagination {...props} />;

  return (
    <TablePagination
      {...props}
      labelDisplayedRows={labelDisplayedRows}
      ActionsComponent={ActionsComponent}
    />
  );
}

export default CustomTablePagination;
