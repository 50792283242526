import styled from 'styled-components';
import { COLOR } from '../../styles/color';

export const StyledPrebuiltBotTabs = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 25px;

  .samples {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e8ebf3;
    border-radius: 8px;
    margin: 5px 0;
    padding: 10px 15px;
    color: ${COLOR.navbarAppName};
    background-color: ${COLOR.background};

    .name {
      margin-right: auto;
      font-weight: 700;
    }
  }

  .features {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .feature {
      display: flex;
      gap: 8px;
    }

    .check {
      color: ${COLOR.primary};
    }

    .content {
      font-weight: 700;
      color: ${COLOR.navbarAppName};
    }
  }

  .tab {
    font-weight: 400;
    font-size: 16px;
    text-transform: none;
    border-bottom: 1px solid ${COLOR.lightText};
  }

  .Mui-selected {
    font-weight: 700;
    color: ${COLOR.primary};
  }
`;

export const StyledBotDetail = styled.div`
  margin-bottom: 25px;

  .header {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 25px;
  }

  .basic-info {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    margin-bottom: 25px;

    .name {
      font-weight: 700;
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .model-info {
    display: flex;
    gap: 45px;
    margin-bottom: 25px;
    .info {
      display: flex;
      gap: 6px;

      .value {
        font-weight: 700;
        font-size: 24px;
      }
    }
  }

  .title {
    color: ${COLOR.navbarAppName};
  }

  .description {
    margin-bottom: 10px;
  }
`;
