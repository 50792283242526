import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  dashboardWrapper: {
    display: 'grid',
    gridGap: '32px',
  },
  data: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(560px, 1fr))',
    gridGap: '32px',

    '@media only screen and (min-width: 1300px)': {
      gridTemplateColumns: '1fr 1px 1fr',
    },
  },
  divider: {
    display: 'none',

    '@media only screen and (min-width: 1300px)': {
      display: 'block',
    },
  },
}));
