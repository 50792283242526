import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import useStyles from './index.style';

const NoPermission = ({ backText, handleBack }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.wrapper}>
      <Warning className={classes.icon} />
      <Typography variant="h6" className={classes.text}>
        {t('notPermission')}
      </Typography>
      <Button color="primary" variant="contained" onClick={handleBack}>
        {backText}
      </Button>
    </Grid>
  );
};

export default NoPermission;
