import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import IntegrateBot from './IntegrateBot';
import AppConfig from './AppConfig';
import PrebuiltBotDetail from './PrebuiltBotDetail';

const PAGE = {
  INTEGRATE_BOT: 'INTEGRATE_BOT',
  APP_CONFIG: 'APP_CONFIG',
  PRE_BUILT_BOT_DETAIL: 'PRE_BUILT_BOT_DETAIL',
};

const CreateAppDialog = ({ visibleAppModel, setVisibleApp }) => {
  const [curPage, setCurPage] = useState(PAGE.INTEGRATE_BOT);
  const [detailPrebuiltBotId, setDetailPrebuiltBotId] = useState('');
  const [selectedPrebuiltBot, setSelectedPrebuiltBot] = useState({});

  const handleToSecondStep = () => {
    setCurPage(PAGE.APP_CONFIG);
  };

  const handleToFirstStep = () => {
    setDetailPrebuiltBotId('');
    setSelectedPrebuiltBot({});
    setCurPage(PAGE.INTEGRATE_BOT);
  };

  const handleRemoveConfig = () => {
    setCurPage(PAGE.INTEGRATE_BOT);
    setVisibleApp();
  };

  const handleViewPrebuiltBot = (prebuiltBotId) => {
    setDetailPrebuiltBotId(prebuiltBotId);
    setCurPage(PAGE.PRE_BUILT_BOT_DETAIL);
  };

  const handleUsePrebuiltBot = (prebuiltBot) => {
    setSelectedPrebuiltBot(prebuiltBot);
    setCurPage(PAGE.APP_CONFIG);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={visibleAppModel}
      onClose={handleRemoveConfig}
    >
      <DialogContent>
        {curPage === PAGE.INTEGRATE_BOT && (
          <IntegrateBot
            toSecondStep={handleToSecondStep}
            viewPrebuiltBot={handleViewPrebuiltBot}
            usePrebuiltBot={handleUsePrebuiltBot}
          />
        )}
        {curPage === PAGE.APP_CONFIG && (
          <AppConfig
            selectedPrebuiltBot={selectedPrebuiltBot}
            onToFirstStep={handleToFirstStep}
            onRemoveConfig={handleRemoveConfig}
          />
        )}
        {curPage === PAGE.PRE_BUILT_BOT_DETAIL && (
          <PrebuiltBotDetail
            detailPrebuiltBotId={detailPrebuiltBotId}
            onToFirstStep={handleToFirstStep}
            onUsePrebuiltBot={handleUsePrebuiltBot}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateAppDialog;
