import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PublicRoute({
  component: Component,
  restricted,
  isLoginRoute,
  ...rest
}) {
  const { accessToken } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        accessToken && restricted ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default PublicRoute;
