import axiosInstance from './axiosInstance';

const { REACT_APP_SMARTDIALOG_API_DOMAIN } = process.env;

export async function getListBots({ search, limit, offset }) {
  const response = await axiosInstance({
    method: 'GET',
    url: `${REACT_APP_SMARTDIALOG_API_DOMAIN}/api/v1/agents`,
    params: { search, limit, offset },
  });
  return response;
}

export async function createBot({ name, description, domain, prebuiltBotId }) {
  const data = { name, description, domain };
  if (prebuiltBotId) data.prebuiltBotId = prebuiltBotId;

  const response = await axiosInstance({
    method: 'POST',
    url: `${REACT_APP_SMARTDIALOG_API_DOMAIN}/api/v2/bots`,
    data,
  });
  return response;
}

export async function importBot({ botId, importBotFile }) {
  const formData = new FormData();
  formData.append('file', importBotFile);

  const response = await axiosInstance({
    method: 'POST',
    url: `${REACT_APP_SMARTDIALOG_API_DOMAIN}/api/v1/agents/${botId}/imports`,
    data: formData,
    processData: false,
    contentType: false,
  });

  return response;
}

export async function getPreBuiltBots({ search, limit, offset }) {
  const response = await axiosInstance({
    method: 'GET',
    url: `${REACT_APP_SMARTDIALOG_API_DOMAIN}/api/v2/pre-built-bots`,
    params: { search, limit, offset },
  });
  return response;
}

export const getPreBuiltBotById = async (prebuiltBotId) => {
  const response = await axiosInstance({
    method: 'GET',
    url: `${REACT_APP_SMARTDIALOG_API_DOMAIN}/api/v2/pre-built-bots/${prebuiltBotId}`,
  });

  return response;
};
