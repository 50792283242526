// import Login from '../../containers/Login';
import Dashboard from '../../containers/Dashboard';
// import AccountSetting from '../../containers/AccountSetting';
import AdminAccounts from '../../containers/Admin/Accounts';
import AdminApps from '../../containers/Admin/Apps';
import AdminBots from '../../containers/Admin/Bots';

const appRoutes = {
  // login: {
  //   url: '/login',
  //   component: Login,
  //   private: false,
  //   restricted: true,
  // },
  dashboard: {
    url: '/dashboard',
    component: Dashboard,
    private: true,
    restricted: true,
  },
  // accountSetting: {
  //   url: '/settings',
  //   component: AccountSetting,
  //   private: true,
  //   restricted: true,
  // },
  adminAccounts: {
    url: '/admin/accounts',
    component: AdminAccounts,
    private: true,
    restricted: true,
  },
  adminApps: {
    url: '/admin/apps',
    component: AdminApps,
    private: true,
    restricted: true,
  },
  adminBots: {
    url: '/admin/bots',
    component: AdminBots,
    private: true,
    restricted: true,
  },
};

export default appRoutes;
