import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { SnackbarProvider } from 'notistack';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import store from './redux/store';
import { getCookie } from './utils/cookie';
import AppRouter from './components/Routes/AppRouter';
import theme from './theme';
import { handleTokens } from './services/auth';
import { IAM_URL, IAM_REALM, IAM_CLIENT_ID } from './configs';

const keycloak = Keycloak({
  url: `${IAM_URL}/auth`,
  realm: IAM_REALM,
  clientId: IAM_CLIENT_ID,
});

const notistackRef = React.createRef();

function App() {
  const refreshToken = getCookie('refreshToken');

  const onClickDismiss = (key) => () => {
    if (notistackRef.current) notistackRef.current.closeSnackbar(key);
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        refreshToken,
      }}
      onTokens={handleTokens}
    >
      <ThemeProvider theme={theme}>
        <Provider store={store()}>
          <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <ClearIcon style={{ color: 'whitesmoke' }} />
              </IconButton>
            )}
          >
            <AppRouter />
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
