import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.warning.main,
    fontSize: 100,
  },
  text: {
    marginBottom: 16,
  },
}));
