import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import PrebuiltBotDetail from '../../../components/PrebuiltBotDetail';

import { StyledBotDetail } from './index.style';
import apis from '../../../apis';

const PreBuiltBotDetail = ({
  prebuiltBotId,
  backToBotsList,
  setSelectedBot,
}) => {
  const [bot, setBot] = useState({});
  const { t } = useTranslation();

  const fetchPrebuiltBot = async () => {
    const response = await apis.smartdialog.getPreBuiltBotById(prebuiltBotId);
    if (response?.data?.status) setBot(response?.data?.result);
  };

  useEffect(() => {
    fetchPrebuiltBot();
  }, [prebuiltBotId]);

  const handleSelectBot = () =>
    setSelectedBot({
      id: bot.id,
      name: bot.name,
      logo: bot.logo,
    });

  return (
    <StyledBotDetail>
      <PrebuiltBotDetail bot={bot} />
      <div className="action">
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={backToBotsList}
        >
          {t('backToPrebuiltBotList')}
        </Button>
        <Button
          variant="contained"
          className="useNow"
          onClick={handleSelectBot}
        >
          {t('useNow')}
        </Button>
      </div>
    </StyledBotDetail>
  );
};

export default PreBuiltBotDetail;
