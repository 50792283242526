import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  FormControl,
  Input,
  InputAdornment,
  ButtonBase,
} from '@material-ui/core';
import { AddCircleOutlineOutlined, Search } from '@material-ui/icons';
import useStyles from './index.style';
import Card from '../../../components/Card';
import NoData from '../../../components/NoData';
import CreateBotDialog from './CreateBotDialog';
import { changeAlias } from '../../../utils/string';
import api from '../../../apis';
import { useDebounce } from '../../../utils/hooks';

const MAX_ITEM = 5;

const ListBot = () => {
  const [bots, setBots] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [visibleBotModel, setVisibleBotModel] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 600);
  const [page, setPage] = useState(0);

  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fetchBots = async () => {
    const { data } = await api.smartdialog.getListBots({
      search: debouncedSearch,
      limit: MAX_ITEM,
      offset: page * MAX_ITEM,
    });
    if (data.status) {
      setBots(data.results.agents);
      setTotalCount(data.results.metadata.total);
    } else {
      enqueueSnackbar(t('cannotFetchBot'), { variant: 'error' });
    }
  };

  const handleViewBot = (id) => {
    window.location.assign(
      `${process.env.REACT_APP_SMARTDIALOG_DASHBOARD_DOMAIN}/api-client/agent/${id}/dashboard`,
    );
  };

  useEffect(() => {
    fetchBots();
  }, [debouncedSearch, page]);

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const renderPagination = () => {
    if (totalCount > MAX_ITEM)
      return (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalCount}
          rowsPerPage={MAX_ITEM}
          page={page}
          onChangePage={handleChangePage}
        />
      );
    return null;
  };

  return (
    <div>
      <Typography variant="h5" className={classes.botList}>
        {t('listBot')}
      </Typography>
      <div className={classes.botHeader}>
        <FormControl className={classes.search}>
          <Input
            id="input-search-app"
            startAdornment={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            placeholder={t('search')}
            value={search}
            onChange={handleChangeSearch}
          />
        </FormControl>
        <Tooltip title={t('newBot')}>
          <IconButton color="primary" onClick={() => setVisibleBotModel(true)}>
            <AddCircleOutlineOutlined />
          </IconButton>
        </Tooltip>

        <CreateBotDialog
          visibleBotModel={visibleBotModel}
          setVisibleBot={(value) => setVisibleBotModel(value)}
        />
      </div>
      <div className={classes.listBotWrapper}>
        {!bots.length && <NoData text={t('noData')} />}
        {bots.map((bot) => {
          const color = Math.floor(Math.random() * 3);
          return (
            <Card key={bot.id} margin="0 0 16px 0">
              <ButtonBase
                className={classes.item}
                onClick={() => handleViewBot(bot.id)}
              >
                <Avatar
                  variant="rounded"
                  src={bot.logo}
                  className={`${classes.logo} ${
                    color === 0 && classes.yellow
                  } ${color === 1 && classes.pink} ${
                    color === 2 && classes.blue
                  }`}
                >
                  {!bot.logo && changeAlias(bot.name).slice(0, 1)}
                </Avatar>
                <div className={classes.botData}>
                  <div className={classes.infoUp}>
                    <Typography className={classes.botName}>
                      {bot.name}
                    </Typography>
                    <Typography>
                      {`${t('lastTrain')}: ${bot.lastTrain || t('noData')}`}
                    </Typography>
                  </div>
                  <div className={classes.infoBottom}>
                    <Typography>
                      {`${t('intent')}: ${
                        bot.numberOfIntents
                          ? bot.numberOfIntents.toLocaleString()
                          : 0
                      }`}
                    </Typography>
                    <Typography>
                      {`${t('userSay')}: ${
                        bot.numberOfUserSays
                          ? bot.numberOfUserSays.toLocaleString()
                          : 0
                      }`}
                    </Typography>
                    <Typography>
                      {`${t('action')}: ${
                        bot.numberOfActions
                          ? bot.numberOfActions.toLocaleString()
                          : 0
                      }`}
                    </Typography>
                    <Typography>
                      {`${t('entity')}: ${
                        bot.numberOfEntities
                          ? bot.numberOfEntities.toLocaleString()
                          : 0
                      }`}
                    </Typography>
                  </div>
                </div>
              </ButtonBase>
            </Card>
          );
        })}
        {renderPagination()}
      </div>
    </div>
  );
};

export default ListBot;
