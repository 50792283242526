import api from './axiosInstance';
import { CALLBOT_ACCOUNT_URL } from '../configs';

const getMe = async (accessToken) => {
  try {
    const response = await api({
      method: 'GET',
      url: `${CALLBOT_ACCOUNT_URL}/api/v1/me`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return response?.data;
  } catch (error) {
    return error.response;
  }
};

export { getMe };
