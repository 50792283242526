import axiosInstance from './axiosInstance';

const { REACT_APP_LIVECHAT_MANAGEMENT_DOMAIN } = process.env;

export async function getListApps({ search, limit, offset }) {
  const response = await axiosInstance({
    method: 'GET',
    url: `${REACT_APP_LIVECHAT_MANAGEMENT_DOMAIN}/api/v1/apps`,
    params: { search, limit, offset },
  });
  return response;
}

export async function createApp({ name, phase, description, prebuiltBotId }) {
  const response = await axiosInstance({
    method: 'POST',
    url: `${REACT_APP_LIVECHAT_MANAGEMENT_DOMAIN}/api/v1/apps`,
    data: {
      name,
      phase,
      description,
      prebuiltBotId,
    },
  });
  return response;
}
