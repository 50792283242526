import axiosInstance from './axiosInstance';

export async function getUserInfo() {
  const response = await axiosInstance({
    method: 'GET',
    url: '/me',
  });
  return response;
}

export async function getUsers({ search, offset, limit }) {
  const response = await axiosInstance({
    method: 'GET',
    url: '/users',
    params: {
      search,
      offset,
      limit,
      fields: 'id,name,email,avatar',
      isAdmin: false,
    },
  });
  return response;
}

export async function deleteUser(userId) {
  const response = await axiosInstance({
    method: 'DELETE',
    url: `/users/${userId}`,
  });
  return response;
}

export async function updateProfile({ name, avatar }) {
  const response = await axiosInstance({
    method: 'PUT',
    url: '/me',
    data: {
      name,
      avatar,
    },
  });
  return response;
}
